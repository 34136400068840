/* eslint-disable max-len */
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { readViewModelValue, appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import htmlParser from 'html-react-parser';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';

import metadata from './PMQuoteReviewCarComponent.metadata.json5';
import './PMQuoteReviewCarComponent.messages';
import styles from '../Stylesheets/PMQuoteReview.module.scss';

function PMQuoteReviewCarComponent(props) {
    const {
        data: submissionVM,
        readOnlyFields,
        path,
        id,
        index,
        labelPosition,
        onValidate,
        onValueChange,
        showOptional,
        phoneWide,
        wizardData,
        updateWizardData,
        jumpTo,
        history,
        navigateToDetails,
    } = props;
    const translator = useTranslator();
    const vehicleVM = _.get(submissionVM, 'lobData.personalMotor.coverables.pmVehicles.children[0]');
    const monthsList = _.get(submissionVM, 'baseData.accountHolder.ukResidencyMonth_itb.aspects.availableValues');
    const vehicle = vehicleVM.value;
    const [isGeneralAftermarketAccessoriesVisible, setGeneralAftermarketAccessoriesVisible] = useState(false);
    const [isGeneralAftermarketListVisible, setGeneralAftermarketListVisible] = useState(false);
    const [generalAftermarketAccessoriesValue, setGeneralAftermarketAccessoriesValue] = useState('');
    const [isBodyWorkModsVisible, setBodyWorkModsVisible] = useState(false);
    const [isBodyWorkModsListVisible, setBodyWorkModsListVisible] = useState(false);
    const [bodyWorkModsListValue, setBodyWorkModsListValue] = useState('');
    const [isEngineModsVisible, setEngineModsVisible] = useState(false);
    const [isEngineModsListVisible, setEngineModsListVisible] = useState(false);
    const [engineModsListValue, setEngineModsListValue] = useState('');
    const [isWheelsOrTyresModsVisible, setWheelsOrTyresModsVisible] = useState(false);
    const [isWheelsOrTyresModsListVisible, setWheelsOrTyresModsListVisible] = useState(false);
    const [wheelsOrTyresModsListValue, setWheelsOrTyresModsListValue] = useState('');
    const [isConversionModsVisible, setConversionModsVisible] = useState(false);
    const [isConversionModsListVisible, setConversionModsListVisible] = useState(false);
    const [conversionModsListValue, setConversionModsListValue] = useState('');
    const [isOtherModsVisible, setOtherModsVisible] = useState(false);
    const [isOtherModsListVisible, setOtherModsListVisible] = useState(false);
    const [otherModsListValue, setotherModsListValue] = useState('');

    const allModificationAreas = useMemo(() => [
        'generalAfterMarketMods',
        'bodyWorkMods',
        'engineMods',
        'wheelsOrTyresMods',
        'conversionMods',
        'otherMods'
    ], []);

    const dayParking = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'dayTimeParking.aspects.availableValues');
        let dayParking = availableValues.find((item) => item.code === vehicle.dayTimeParking);
        return dayParking ? translator({
            id: dayParking.name,
            defaultMessage: dayParking.name
        }) : '';
    };

    const nightParking = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'nightTimeParking.aspects.availableValues');
        let nightParking = availableValues.find((item) => item.code === vehicle.nightTimeParking);
        return nightParking ? translator({
            id: nightParking.name,
            defaultMessage: nightParking.name
        }) : '';
    };

    const securityDevices = (vehicle) => {
        let availableValues = _.get(vehicleVM, 'securityDevices.aspects.availableValues');
        let securityDevices = availableValues.find((item) => item.code === vehicle.securityDevices);
        return securityDevices ? translator({
            id: securityDevices.name,
            defaultMessage: securityDevices.name
        }) : '';
    };

    const dataPurchasedFormatter = () => {
        let monthIndex = 0;
        if (vehicle.purchasedMonth && vehicle.purchasedYear) {
            if (typeof vehicle.purchasedMonth === 'string') {
                monthIndex = monthsList.findIndex(obj => obj.code === vehicle.purchasedMonth);
            } else {
                monthIndex = vehicle.purchasedMonth;
            }
        }

        return { month: monthIndex, year: vehicle.purchasedYear };
    };

    const onAmendYourCarDetailsClick = () => {
        const initialWizardStepID = 2;
        jumpTo(initialWizardStepID);
    };

    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const handleValueChange = useCallback(
        (value, changedPath) => {
            const fullPath = `${path}.${changedPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path]
    );

    const handleModsClick = useCallback((modType) => {
        const toggleFunctions = {
            generalAfterMarketMods: setGeneralAftermarketListVisible,
            bodyWorkMods: setBodyWorkModsListVisible,
            engineMods: setEngineModsListVisible,
            wheelsOrTyresMods: setWheelsOrTyresModsListVisible,
            conversionMods: setConversionModsListVisible,
            otherMods: setOtherModsListVisible,
        };

        // eslint-disable-next-line no-unused-vars
        const visibilityStates = {
            generalAfterMarketMods: isGeneralAftermarketListVisible,
            bodyWorkMods: isBodyWorkModsListVisible,
            engineMods: isEngineModsListVisible,
            wheelsOrTyresMods: isWheelsOrTyresModsListVisible,
            conversionMods: isConversionModsListVisible,
            otherMods: isOtherModsListVisible,
        };

        if (toggleFunctions[modType]) {
            toggleFunctions[modType]((prevState) => !prevState);
        }
    }, [
        isBodyWorkModsListVisible,
        isConversionModsListVisible,
        isEngineModsListVisible,
        isGeneralAftermarketListVisible,
        isOtherModsListVisible,
        isWheelsOrTyresModsListVisible,
    ]);

    useEffect(() => {
        const LoadAndBuildModifications = () => {
            allModificationAreas.forEach((modificationSection) => {
                const modificationsSet = _.get(vehicleVM, `${modificationSection}.value`);
                if (modificationsSet.length > 0) {
                    let dynamicList = '<ul>';
                    modificationsSet.forEach((modificationItems) => {
                        dynamicList += `<li>${modificationItems}</li>`;
                    });
                    dynamicList += '</ul>';
                    switch (modificationSection) {
                        case 'generalAfterMarketMods':
                            setGeneralAftermarketAccessoriesValue(dynamicList);
                            setGeneralAftermarketAccessoriesVisible(true);
                            break;
                        case 'bodyWorkMods':
                            setBodyWorkModsListValue(dynamicList);
                            setBodyWorkModsVisible(true);
                            break;
                        case 'engineMods':
                            setEngineModsListValue(dynamicList);
                            setEngineModsVisible(true);
                            break;
                        case 'wheelsOrTyresMods':
                            setWheelsOrTyresModsListValue(dynamicList);
                            setWheelsOrTyresModsVisible(true);
                            break;
                        case 'conversionMods':
                            setConversionModsListValue(dynamicList);
                            setConversionModsVisible(true);
                            break;
                        case 'otherMods':
                            setotherModsListValue(dynamicList);
                            setOtherModsVisible(true);
                            break;
                        default:
                    }
                }
            });
        };

        LoadAndBuildModifications();
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid, allModificationAreas, vehicleVM]);

    const writeStepperValue = useCallback(
        (value, changedPath) => {
            const valueToString = _.toString(value);
            handleValueChange(valueToString, changedPath);
        },
        [handleValueChange]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition,
            phoneWide,
            readOnly: true
        },
        [`makeYourCar${index}`]: {
            value: vehicle.make,
        },
        [`modelYourCar${index}`]: {
            value: vehicle.model,
        },
        [`fuelYourCar${index}`]: {
            value: vehicle.fuelType,
        },
        [`registrationYearYourCar${index}`]: {
            value: vehicle.firstRegisteredYear,
        },
        [`valueYourCar${index}`]: {
            value: vehicle.marketValue,
        },
        [`datePurchasedYourCar${index}`]: {
            value: dataPurchasedFormatter(),
        },
        [`securityDevicesYourCar${index}`]: {
            value: securityDevices(vehicle),
        },
        [`securityTrackerYourCar${index}`]: {
            value: vehicle.securityTracker ? 'Yes' : 'No',
        },
        [`vehicleModifiedYourCar${index}`]: {
            value: vehicle.hasBeenModified ? 'Yes' : 'No',
        },
        [`generalAfterMarketContainer${index}`]: {
            visible: isGeneralAftermarketAccessoriesVisible
        },
        [`showHideGeneralModifications${index}`]: {
            content: !isGeneralAftermarketListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('generalAfterMarketMods')
        },
        [`generalAfterMarketModsValues${index}`]: {
            content: htmlParser(generalAftermarketAccessoriesValue),
            visible: isGeneralAftermarketListVisible
        },
        [`bodyWorkModsContainer${index}`]: {
            visible: isBodyWorkModsVisible,
        },
        [`showHideBodyWorkModifications${index}`]: {
            content: !isBodyWorkModsListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('bodyWorkMods')
        },
        [`bodyWorkModsValues${index}`]: {
            content: htmlParser(bodyWorkModsListValue),
            visible: isBodyWorkModsListVisible
        },
        [`engineModsContainer${index}`]: {
            visible: isEngineModsVisible,
        },
        [`showHideEngineModifications${index}`]: {
            content: !isEngineModsListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('engineMods')
        },
        [`engineModsValues${index}`]: {
            content: htmlParser(engineModsListValue),
            visible: isEngineModsListVisible
        },
        [`wheelsOrTyresModsContainer${index}`]: {
            visible: isWheelsOrTyresModsVisible,
        },
        [`showHideWheelsOrTyresModifications${index}`]: {
            content: !isWheelsOrTyresModsListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('wheelsOrTyresMods')
        },
        [`wheelsOrTyresModsValues${index}`]: {
            content: htmlParser(wheelsOrTyresModsListValue),
            visible: isWheelsOrTyresModsListVisible
        },
        [`conversionModsContainer${index}`]: {
            visible: isConversionModsVisible,
        },
        [`showHideConversionModifications${index}`]: {
            content: !isConversionModsListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('conversionMods')
        },
        [`conversionModsValues${index}`]: {
            content: htmlParser(conversionModsListValue),
            visible: isConversionModsListVisible
        },
        [`otherModsContainer${index}`]: {
            visible: isOtherModsVisible,
        },
        [`showHideOtherModifications${index}`]: {
            content: !isOtherModsListVisible ? 'View' : 'Close',
            onClick: () => handleModsClick('otherMods')
        },
        [`otherModsValues${index}`]: {
            content: htmlParser(otherModsListValue),
            visible: isOtherModsListVisible
        },
        [`dayParkingYourCar${index}`]: {
            value: dayParking(vehicle),
        },
        [`nightParkingYourCar${index}`]: {
            value: nightParking(vehicle),
        },
        [`estimatedMileageYourCar${index}`]: {
            value: vehicle.annualMileage,
        },
        [`registeredKeeperYourCar${index}`]: {
            value: vehicle.registeredKeeper ? 'Yes' : 'No',
        },
        [`rightHandDriveYourCar${index}`]: {
            value: vehicle.rightHandDrive ? 'Yes' : 'No',
        },
        [`importedYourCar${index}`]: {
            value: vehicle.imported ? 'Yes' : 'No',
        },
        [`howManySeatsYourCar${index}`]: {
            value: vehicle.lessThanEightSeats ? 'Yes' : 'No',
        },
        [`ncdYearsYourCar${index}`]: {
            value: vehicle.ncdYears,
        },
        [`amendDetailsYourCar${index}`]: {
            onClick: () => {
                if (navigateToDetails) {
                    navigateToDetails('vehicle-details');
                }
            }
        },

    };

    const resolvers = {
        resolveClassNameMap: styles
    };
    return (
        <ViewModelForm
            model={submissionVM}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValidate={setComponentValidation}
            onValueChange={handleValueChange}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}
PMQuoteReviewCarComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.string
};
PMQuoteReviewCarComponent.defaultProps = {
    data: {},
    phoneWide: {
        labelPosition: 'top'
    },
    labelPosition: 'left',
    path: undefined,
    showOptional: true,
    id: undefined,
    index: 0
};
export default PMQuoteReviewCarComponent;
