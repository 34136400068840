import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleLicensePlate: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.License Plate',
        defaultMessage: 'License Plate',
    },
    paVehicleLicenseState: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.State',
        defaultMessage: 'State',
    },
    paVehicleCostNew: {
        id: 'quoteandbind.pm.directives.templates.pm-edit-vehicle.Cost New',
        defaultMessage: 'Cost New',
    },
    subtextCostOfReplacementKFI: {
        id: 'platform.inputs.vehicle-details.marketValue.subLabel.kfi',
        defaultMessage: '<i>This is the cost of replacement with one of the same make, model and specification, considering the age, mileage and condition.</i>'
    },
    subtextCostOfReplacementTYA: {
        id: 'platform.inputs.vehicle-details.marketValue.subLabel.tya',
        defaultMessage: 'This is the cost of replacement with one of the same make, model and specification, considering the age, mileage and condition.'
    },
    hasBeenModified: {
        id: 'platform.inputs.vehicle-details.hasBeenModified.subLabel',
        defaultMessage: '<ul><li>Any genuine manufacturer approved options or accessories</li><li>Manufacturer approved “Special Editions”</li><li>Any adaptation to accommodate the use of the vehicle by a disabled person</li></ul>'
    },
    subtextNotIncludeManufacturerKFI: {
        id: 'quoteandbind.views.vehicle-details.modification.sublabel.kfi',
        defaultMessage: '<i>This does not include manufacturer modifications or those to assist disabled drivers.</i>'
    },
    subtextNotIncludeManufacturerTYA: {
        id: 'quoteandbind.views.vehicle-details.modification.sublabel.tya',
        defaultMessage: 'This does not include manufacturer modifications or those to assist disabled drivers.'
    },
    subtextSpouseKFI: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.sublabel.kfi',
        defaultMessage: '<i>*(or your spouse, civil partner or common law partner)</i>'
    },
    subtextSpouseTYA: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.sublabel.tya',
        defaultMessage: '*(or your spouse, civil partner or common law partner)'
    },
    addModifiedButton: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.sublabel.addModified',
        defaultMessage: 'Add Modification'
    },
    removeModifiedButton: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.sublabel.removeModified',
        defaultMessage: 'Remove All'
    },
    typeAheadDropdownDefault: {
        id: 'platform.inputs.vehicle-details.modifications.typeAheadDropdown.defaultTest',
        defaultMessage: 'Please select...'
    },
    generalAftermarketAccessories: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.generalAftermarketModifications',
        defaultMessage: ' General aftermarket accessories'
    },
    bodyworkModifications: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.bodyworkModifications',
        defaultMessage: ' Bodywork Modifications'
    },
    engineModifications: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.engineModifications',
        defaultMessage: ' Engine Modifications'
    },
    wheelsOrTyreModifications: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.wheelsOrModifications',
        defaultMessage: ' Wheels or Tyre Modifications'
    },
    otherModifications: {
        id: 'platform.inputs.vehicle-details.registeredKeeper.otherModifications',
        defaultMessage: ' Other Modifications'
    },
});
