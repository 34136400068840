import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';
import { brandingData } from 'gw-portals-branding-js';

export default class PolicyService {
    static modifications = null;

    static getModificationLists() {
        if (this.modifications === null) {
            const serviceEndpoint = getProxiedServiceUrl('quote');
            const method = 'getModificationLists';
            const brand = [brandingData.BRANDING];

            this.modifications = JsonRPCService.send(serviceEndpoint, method, brand);
        }
        return this.modifications;
    }
}
