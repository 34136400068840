import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleGeneralModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.General.Modifications',
        defaultMessage: 'General Modifications',
    },
    paVehicleBodyworkModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Bodywork.Modifications',
        defaultMessage: 'Body Work Modifications',
    },
    paVehicleEngineModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Engine.Modifications',
        defaultMessage: 'Engine Modifications',
    },
    paVehicleWheelsOrTyresModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Wheels.Tyres.Modifications',
        defaultMessage: 'Wheel or Tyres Modifications',
    },
    paVehicleConversionModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Conversion.Modifications',
        defaultMessage: 'Conversion Modifications',
    },
    paVehicleOtherModifications: {
        id: 'quoteandbind.pm.directives.templates.pm-quote-review.Other.Modifications',
        defaultMessage: 'Other Modifications',
    },
});
