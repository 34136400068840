/* eslint-disable max-len */
import React, {
    useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { appendMetadataWithIndex } from 'gw-jutro-adapters-react';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './PMVehicleComponent.metadata.json5';
import messagesTranslationsSet from './PMVehicleComponent.messages';
import moment from 'moment';
import styles from './PMVehicleComponent.modules.scss';
import BrandingData from 'gw-portals-branding-js/BrandingData';
import htmlParser from 'html-react-parser';
import PolicyService from 'gw-capability-quoteandbind/services/PolicyService';

function PMVehicleComponent(props) {
    const {
        data: vehicleVM,
        readOnlyFields,
        phoneWide,
        labelPosition,
        showOptional,
        path,
        id,
        index,
        onValidate,
        submitted,
        setVehLookupSuccessful,
        onValueChange,
        handleRegModeActive,
        handleShowManualModeClicked,
        basePeriodStartDate,
    } = props;
    const requireFieldError = useMemo(() => ['This field is required'], []);
    const invalidDateError = useMemo(() => ['Invalid Date'], []);
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const [showPurchaseField, setShowPurchaseField] = useState(false);
    const [showInvalidDateMessage, setInvalidDateMessage] = useState(false);
    const [showHasBeenModifiedVisible, setHasBeenModifiedVisible] = useState(false);
    const [minRegisteredYear, setMinRegisteredYear] = useState(Date.parse(`${vehicleVM.firstRegisteredMonth.value || 1} 1, ${vehicleVM.firstRegisteredYear.value || 1900}`));
    const formattedMetadata = useMemo(() => {
        return appendMetadataWithIndex(metadata.pageContent, index);
    }, [index]);

    const monthList = _.get(vehicleVM, 'purchasedMonth.aspects.availableValues');
    const translator = useContext(TranslatorContext);
    const brand = BrandingData.BRANDING;
    const [marketValue, setMarketValue] = useState(_.get(vehicleVM.value, 'marketValue.amount'));
    const [modificationOptions, setModificationOptions] = useState([]);
    const [isModificationValuesLoaded, setModificationValuesLoaded] = useState(false);
    const [isGeneralAftermarketAccessoriesVisible, setGeneralAftermarketAccessoriesVisible] = useState(false);
    const [isBodyWorkModsVisible, setBodyWorkModsVisible] = useState(false);
    const [isEngineModsVisible, setEngineModsVisible] = useState(false);
    const [isWheelsTiresModsVisible, setWheelsTiresModsVisible] = useState(false);
    const [isOtherModsVisible, setOtherModsVisible] = useState(false);
    const allModificationAreas = useMemo(() => [
        'generalAfterMarketMods',
        'bodyWorkMods',
        'engineMods',
        'wheelsOrTyresMods',
        'conversionMods',
        'otherMods'
    ], []);
    const availableValuesByBrand = () => {
        if (brand === 'kfi') {
            return vehicleVM.vehicleLeasingCompany.aspects.availableValues[0].typelist.getFilter('KFILeaseOptions').codes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            }));
        }
        return vehicleVM.vehicleLeasingCompany.aspects.availableValues[0].typelist.codes.map((typeCode) => ({
            code: typeCode.code,
            name: translator({
                id: typeCode.name,
                defaultMessage: typeCode.name
            })
        }));
    };


    const getModificationsAvailableValues = () => {
        const noModifications = { code: 'no', name: 'No' };
        const updatedValues = [noModifications];
        vehicleVM.modification.aspects.availableValues.map((availableValue) => {
            updatedValues.push({
                code: availableValue.code,
                name: translator({
                    id: availableValue.name,
                    defaultMessage: availableValue.name
                })
            });
        });

        return updatedValues;
    };

    const getVehicleMod = () => {
        const values = getModificationsAvailableValues();
        if (vehicleVM.value.hasBeenModified === false) {
            return values.find((mod) => {
                return mod.code === 'no';
            });
        }
        return values.find((mod) => {
            return mod.code === vehicleVM.modification.code;
        });
    };
    const [getModificationChangeValue, setModificationChangeValue] = useState(getVehicleMod());

    const YESNOFieldavailableValues = [
        { code: true, displayName: 'Yes' },
        { code: false, displayName: 'No' },
    ];

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }

        PolicyService.getModificationLists()
            .then((modifications) => {
                const resultSet = {};
                const modificationsMapper = {
                    generalAftermarketAccessories: 0, bodyworkModifications: 1, engineModifications: 2, wheelsOrTyresModifications: 3, otherModifications: 5
                };
                Object.entries(modificationsMapper).forEach(([modificationKey, arrayPosition]) => {
                    if (modifications[arrayPosition] !== undefined) {
                        resultSet[modificationKey] = modifications[arrayPosition]
                    }
                });
                setModificationOptions(resultSet);
            });
    }, [id, onValidate, isComponentValid, vehicleVM]);

    const handleRegModeChanges = (value) => {
        handleRegModeActive(value);
    };
    const handleShowManualMode = (value) => {
        handleShowManualModeClicked(value);
    };
    const getMonthYearFieldValue = (monthDtoPath, yearDtoPath) => {
        const dtoMonth = _.get(vehicleVM, monthDtoPath);
        const dtoYear = _.get(vehicleVM, yearDtoPath);
        if (dtoMonth === undefined || dtoYear === undefined) {
            return undefined;
        // eslint-disable-next-line no-else-return
        } else {
            let monthNum = 0;
            monthList.forEach((month, index) => {
                if (month.code === dtoMonth.code) {
                    monthNum = index;
                }
            });
            return { month: monthNum, year: dtoYear };
        }
    };

    const [purchasedCarMonthYear, setPurchasedCarMonthYear] = useState(getMonthYearFieldValue(
        'purchasedMonth.value',
        'purchasedYear.value'
    ));

    const handleValueChange = useCallback(
        (value, changedPath) => {
            if (changedPath === 'vehicle.abicode' || changedPath === 'vehicle.firstRegisteredYear') {
                setShowPurchaseField(true);
            }
            if (changedPath === 'vehicle.firstRegisteredYear') {
                setPurchasedCarMonthYear(undefined);
                _.set(vehicleVM.value, 'purchasedMonth', undefined);
                _.set(vehicleVM.value, 'purchasedYear', undefined);
            }
            if (changedPath === 'vehicle.marketValue.amount') {
                const newMarketValuePath = 'marketValue';
                const marketValueObj = {
                    amount: value,
                    currency: 'gbp'
                };
                const marketValueRangeRegex = /(^\d{0,6}$)|(^\d{0,6}[.]\d{1,2}$)/;
                if (marketValueRangeRegex.test(value)) {
                    setMarketValue(value);
                    _.set(vehicleVM.value, newMarketValuePath, marketValueObj);
                }
            }
            const newPath = changedPath.replace(/^vehicle\./, '');
            const fullPath = `${path}.${newPath}`;
            if (onValueChange) {
                onValueChange(value, fullPath);
            }
        },
        [onValueChange, path, vehicleVM]
    );

    const buildJutroCompatibleDate = (objMonth, strYear) => {
        let monthNum = 0;
        if (objMonth !== undefined) {
            monthList.forEach((month, monthIndex) => {
                if (month.code !== undefined && objMonth.code !== undefined) {
                    if (month.code === objMonth.code) {
                        monthNum = monthIndex;
                    }
                }
            });
            return { month: monthNum, year: strYear };
        }
        if (objMonth === undefined) {
            return { month: 0, year: strYear };
        }
        return {};
    };

    const buildDate = (monthPath, yearPath) => {
        const month = _.get(vehicleVM, monthPath);
        const year = _.get(vehicleVM, yearPath);
        return buildJutroCompatibleDate(month, year);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getPurchasedDateRegistrationDateValidator = () => {
        let blnValidDateElement = true;
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        let userEnteredDate = null;
        let vehicleLookupDate = null;
        const nowDate = moment(`${currentYear}-${currentMonth + 1}-01`);
        const objUserEnteredPurchaseDate = buildDate('purchasedMonth.value', 'purchasedYear.value');
        const objVehicleLookupRegisteredDate = buildDate('firstRegisteredMonth.value', 'firstRegisteredYear.value');

        if (objVehicleLookupRegisteredDate && objUserEnteredPurchaseDate) {
            userEnteredDate = moment(`${objUserEnteredPurchaseDate.year}-${objUserEnteredPurchaseDate.month + 1}-01`);
            vehicleLookupDate = moment(`${objVehicleLookupRegisteredDate.year}-${objVehicleLookupRegisteredDate.month + 1}-01`);
            if (!userEnteredDate.isBetween(vehicleLookupDate, nowDate)) {
                blnValidDateElement = false;
                setInvalidDateMessage(true);
            } else {
                blnValidDateElement = true;
                setInvalidDateMessage(false);
            }
        }
        if (objUserEnteredPurchaseDate && !objVehicleLookupRegisteredDate) {
            userEnteredDate = moment(`${objUserEnteredPurchaseDate.year}-${objUserEnteredPurchaseDate.month + 1}-01`);
            if (userEnteredDate.isAfter(nowDate)) {
                blnValidDateElement = false;
                setInvalidDateMessage(true);
            } else {
                blnValidDateElement = true;
                setInvalidDateMessage(false);
            }
        }
        return blnValidDateElement;
    };

    const hasYourCarBeenModifiedDisplayError = useCallback(() => {
        const hasBeenModifiedValue = _.get(vehicleVM, 'hasBeenModified.value');
        const requireSubModificationFieldError = ['Detail is required in the field(s) below'];

        if (hasBeenModifiedValue === true) {
            const modsCheckSet = [];
            // eslint-disable-next-line no-unused-vars
            allModificationAreas.forEach((element, modIndex) => {
                const modificationsSet = _.get(vehicleVM, `${element}.value`);
                // eslint-disable-next-line no-unneeded-ternary
                modsCheckSet.push(modificationsSet?.length ? true : false);
            });
            const hasAnyModifications = modsCheckSet.some((value) => value === true);
            if (hasAnyModifications === false && submitted) return requireSubModificationFieldError;

            return [];
        }
        if (hasBeenModifiedValue === undefined && submitted) return requireFieldError;

        return [];
    }, [allModificationAreas, requireFieldError, submitted, vehicleVM]);

    const purchasedDateValidationsMessage = () => {
        let arrMessage = [];
        if (showInvalidDateMessage) {
            arrMessage = invalidDateError;
        } else if (!purchasedCarMonthYear) {
            arrMessage = requireFieldError;
        }
        return arrMessage;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const manageModificationInteractions = useCallback((targetModification = 'all', targetModificationTask = 'clearDown') => {
        let modificationCleanSet = [];

        if (allModificationAreas.includes(targetModification)) {
            modificationCleanSet.push(targetModification);
        } else {
            modificationCleanSet = allModificationAreas;
        }

        modificationCleanSet.forEach((currentTargetModification) => {
            switch (currentTargetModification) {
                case 'generalAfterMarketMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'generalAfterMarketMods.value', []);
                        if (isGeneralAftermarketAccessoriesVisible) setGeneralAftermarketAccessoriesVisible(false);
                    } else {
                        const attachedModifications = _.get(vehicleVM, `${currentTargetModification}.value`);
                        setGeneralAftermarketAccessoriesVisible(Boolean(Array.isArray(attachedModifications) && attachedModifications.length));
                    }
                    break;
                case 'bodyWorkMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'bodyWorkMods.value', []);
                        if (isBodyWorkModsVisible) setBodyWorkModsVisible(false);
                    } else {
                        const attachedModifications = _.get(vehicleVM, `${currentTargetModification}.value`);
                        setBodyWorkModsVisible(Boolean(Array.isArray(attachedModifications) && attachedModifications.length));
                    }
                    break;
                case 'engineMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'engineMods.value', []);
                        if (isEngineModsVisible) setEngineModsVisible(false);
                    } else {
                        const attachedModifications = _.get(vehicleVM, `${currentTargetModification}.value`);
                        setEngineModsVisible(Boolean(Array.isArray(attachedModifications) && attachedModifications.length));
                    }
                    break;
                case 'wheelsOrTyresMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'wheelsOrTyresMods.value', []);
                        if (isWheelsTiresModsVisible) setWheelsTiresModsVisible(false);
                    } else {
                        const attachedModifications = _.get(vehicleVM, `${currentTargetModification}.value`);
                        setWheelsTiresModsVisible(Boolean(Array.isArray(attachedModifications) && attachedModifications.length));
                    }
                    break;
                case 'conversionMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'conversionMods.value', []);
                    }
                    break;
                case 'otherMods':
                    if (targetModificationTask === 'clearDown') {
                        _.set(vehicleVM, 'otherMods.value', []);
                        if (isOtherModsVisible) setOtherModsVisible(false);
                    } else {
                        const attachedModifications = _.get(vehicleVM, `${currentTargetModification}.value`);
                        setOtherModsVisible(Boolean(Array.isArray(attachedModifications) && attachedModifications.length));
                    }
                    break;
                default:
                    break;
            }
            if (targetModificationTask === 'valueExistsCheck') setModificationValuesLoaded(true);
        });
    });

    const toggleModsAction = useCallback((modType, isVisible, setVisible) => {
        if (!isVisible) {
            setVisible(true);
        } else {
            setVisible(false);
        }
        manageModificationInteractions(modType);
    }, [manageModificationInteractions]);

    const toggleGeneralAfterMarketButtonAction = useCallback(() => {
        toggleModsAction('generalAfterMarketMods', isGeneralAftermarketAccessoriesVisible, setGeneralAftermarketAccessoriesVisible);
    }, [toggleModsAction, isGeneralAftermarketAccessoriesVisible]);

    const toggleBodyWorkModsButtonAction = useCallback(() => {
        toggleModsAction('bodyWorkMods', isBodyWorkModsVisible, setBodyWorkModsVisible);
    }, [toggleModsAction, isBodyWorkModsVisible]);

    const toggleEngineModsAction = useCallback(() => {
        toggleModsAction('engineMods', isEngineModsVisible, setEngineModsVisible);
    }, [toggleModsAction, isEngineModsVisible]);

    const toggleWheelTireModsAction = useCallback(() => {
        toggleModsAction('wheelsOrTyresMods', isWheelsTiresModsVisible, setWheelsTiresModsVisible);
    }, [toggleModsAction, isWheelsTiresModsVisible]);

    const toggleOtherModsAction = useCallback(() => {
        toggleModsAction('otherMods', isOtherModsVisible, setOtherModsVisible);
    }, [toggleModsAction, isOtherModsVisible]);

    const handleHasBeenModifiedValueChange = useCallback(
        (value) => {
            if (value === false) {
                _.set(vehicleVM.value, 'hasBeenModified', false);
                manageModificationInteractions('all');
            } else {
                _.set(vehicleVM.value, 'hasBeenModified', true);
            }
            if (onValueChange) {
                onValueChange(vehicleVM.value, path);
            }
        },
        [manageModificationInteractions, onValueChange, path, vehicleVM.value]
    );

    const handleModificationValueChange = useCallback(
        (value) => {
            setModificationChangeValue(value);
            let selectVehicleMod = null;
            vehicleVM.modification.aspects.availableValues.forEach((mods) => {
                if (mods.code === value) {
                    selectVehicleMod = mods;
                }
            });
            manageModificationInteractions('all');
            if (value === 'no') {
                _.set(vehicleVM.value, 'modification', null);
                _.set(vehicleVM.value, 'hasBeenModified', false);
            } else {
                _.set(vehicleVM, 'modification', selectVehicleMod);
                _.set(vehicleVM.value, 'hasBeenModified', true);
            }
            if (onValueChange) {
                onValueChange(vehicleVM.value, path);
            }
        },
        [manageModificationInteractions, onValueChange, path, vehicleVM]
    );

    useEffect(() => {
        setMinRegisteredYear(Date.parse(`${vehicleVM.firstRegisteredMonth.value?.code || 1} 1, ${vehicleVM.firstRegisteredYear.value || 1900}`));
        if (!isModificationValuesLoaded) manageModificationInteractions('all', 'valueExistsCheck');
    }, [isModificationValuesLoaded, manageModificationInteractions, vehicleVM.firstRegisteredMonth.value, vehicleVM.firstRegisteredYear.value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const carRegisteredDate = new Date(minRegisteredYear);

    const handleDatePurchasedValueChange = useCallback(
        (value) => {
            if (value !== undefined) {
                const currentYear = new Date().getFullYear();
                const objVehicleLookupRegisteredDate = buildDate('firstRegisteredMonth.value', 'firstRegisteredYear.value');
                const userEnteredDate = new Date(value.year, value.month, 2);
                const vehicleRegisteredDate = new Date(objVehicleLookupRegisteredDate.year, objVehicleLookupRegisteredDate.month, 1);
                if (value.year && (
                    (userEnteredDate.getFullYear() > currentYear) || (userEnteredDate.getFullYear() < vehicleRegisteredDate.getFullYear()) ||
                    ((userEnteredDate.getFullYear() === vehicleRegisteredDate.getFullYear()) && (userEnteredDate.getMonth() < vehicleRegisteredDate.getMonth())))) {
                    _.set(vehicleVM.value, 'purchasedMonth', undefined);
                    _.set(vehicleVM.value, 'purchasedYear', undefined);
                    setInvalidDateMessage(true);
                    setPurchasedCarMonthYear(undefined);
                    return;
                }
                const selectedMonth = monthList[value.month].code;
                _.set(vehicleVM.value, 'purchasedMonth', selectedMonth);
                _.set(vehicleVM.value, 'purchasedYear', value.year);
                setPurchasedCarMonthYear({
                    month: value.month,
                    year: value.year
                });

                let isValidDate = false;
                if (value.year >= carRegisteredDate.getFullYear()) {
                    isValidDate = true;
                }
                setInvalidDateMessage(!isValidDate);
            }
            if (onValueChange) {
                onValueChange(vehicleVM.value, path);
            }
        },
        [onValueChange, buildDate, getPurchasedDateRegistrationDateValidator, monthList, vehicleVM.value, carRegisteredDate, path]
    );

    const overrideProps = {
        '@field': {
            showOptional,
            labelPosition: 'top',
            phoneWide
        },
        [`regLookup${index}`]: {
            readOnly: _.includes(readOnlyFields, 'reglookup'),
            submitted: submitted,
            setVehLookupSuccessful: setVehLookupSuccessful,
            handleRegModeChanges: handleRegModeChanges,
            handleShowManualMode: handleShowManualMode,
        },
        [`securityTracker${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.securityTracker.value === undefined && submitted ? requireFieldError : []

        },
        [`securityDevices${index}`]: {
            validationMessages: vehicleVM.securityDevices.value === undefined && submitted ? requireFieldError : []

        },
        [`imported${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.imported.value === undefined && submitted ? requireFieldError : []
        },
        [`registeredKeeper${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.registeredKeeper.value === undefined && submitted ? requireFieldError : [],
            secondaryLabel: htmlParser(translator(messagesTranslationsSet[`subtextSpouse${brand.toUpperCase()}`].defaultMessage))
        },
        [`doYouLeaseTheVehicle${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.vehicleIsLeased.value === undefined && submitted ? requireFieldError : [],
            visible: vehicleVM.registeredKeeper.value === false && vehicleVM.registeredKeeper.value !== null
        },
        [`whoIsLeasingTheVehicle${index}`]: {
            availableValues: !_.isEmpty(vehicleVM) ? availableValuesByBrand() : [],
            validationMessages: vehicleVM.vehicleLeasingCompany.value === undefined && submitted ? requireFieldError : [],
            visible: vehicleVM.registeredKeeper.value === false && vehicleVM.vehicleIsLeased?.value === true && vehicleVM.vehicleIsLeased?.value !== null
        },
        [`whoIsTheVehicleOwner${index}`]: {
            validationMessages: vehicleVM.vehicleOwner.value === undefined && submitted ? requireFieldError : [],
            visible: vehicleVM.registeredKeeper.value === false && vehicleVM.vehicleIsLeased?.value === true && vehicleVM.vehicleLeasingCompany.value?.name != null && vehicleVM.vehicleLeasingCompany.value?.code !== 'other'
        },
        [`annualMileage${index}`]: {
            validationMessages: (vehicleVM.annualMileage.value == '' || vehicleVM.annualMileage.value == null) ? requireFieldError : []
        },
        [`classOfUse${index}`]: {
            validationMessages: vehicleVM.classOfUse.value === undefined && submitted ? requireFieldError : [],
            className: (brand === 'kfi') ? 'gwClassOfUseVehicleRadio' : ''
        },
        [`rightHandDrive${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.rightHandDrive.value === undefined && submitted ? requireFieldError : []
        },
        [`lessThanEightSeats${index}`]: {
            availableValues: YESNOFieldavailableValues,
            validationMessages: vehicleVM.lessThanEightSeats.value === undefined && submitted ? requireFieldError : []
        },
        [`modification${index}`]: {
            availableValues: getModificationsAvailableValues(),
            onValueChange: handleModificationValueChange,
            value: getModificationChangeValue,
            showErrors: vehicleVM.modification.value === undefined && submitted,
            validationMessages: vehicleVM.modification.value === undefined && submitted ? requireFieldError : [],
            secondaryLabel: htmlParser(translator(messagesTranslationsSet[`subtextNotIncludeManufacturer${brand.toUpperCase()}`].defaultMessage))
        },
        [`hasBeenModified${index}`]: {
            availableValues: YESNOFieldavailableValues,
            onValueChange: handleHasBeenModifiedValueChange,
            secondaryLabel: htmlParser(translator(messagesTranslationsSet.hasBeenModified)),
            validationMessages: hasYourCarBeenModifiedDisplayError()
        },
        [`vehicleHasBeenModifiedContainer${index}`]: {
            visible: vehicleVM.hasBeenModified.value === true
        },
        [`generalAftermarketAccessories${index}`]: {
            content: {
                id: translator(messagesTranslationsSet.generalAftermarketAccessories.id),
                defaultMessage: htmlParser(translator(messagesTranslationsSet.generalAftermarketAccessories.defaultMessage))
            }
        },
        [`generalAfterMarketMods${index}`]: {
            availableValues: !_.isEmpty(modificationOptions.generalAftermarketAccessories) ? modificationOptions.generalAftermarketAccessories.map((modification) => ({
                code: modification,
                name: modification
            })) : [],
            validationMessages: (!vehicleVM.generalAfterMarketMods?.value?.length) && isGeneralAftermarketAccessoriesVisible && submitted ? requireFieldError : [],
            visible: isGeneralAftermarketAccessoriesVisible,
            placeholder: {
                id: 'translation.generalAfterMarketMods.placeholder',
                defaultMessage: isGeneralAftermarketAccessoriesVisible ? messagesTranslationsSet["typeAheadDropdownDefault"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            }
        },
        [`generalAfterMarketButton${index}`]: {
            onClick: () => {
                toggleGeneralAfterMarketButtonAction();
            },
            content: {
                id: isGeneralAftermarketAccessoriesVisible ? messagesTranslationsSet["removeModifiedButton"].id : messagesTranslationsSet["addModifiedButton"].id,
                defaultMessage: isGeneralAftermarketAccessoriesVisible ? messagesTranslationsSet["removeModifiedButton"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            },
            className: !isGeneralAftermarketAccessoriesVisible ? "addModifiedCarButton" : "addModifiedCarButtonWide"
        },
        [`bodyworkModifications${index}`]: {
            content: {
                id: translator(messagesTranslationsSet.bodyworkModifications.id),
                defaultMessage: htmlParser(translator(messagesTranslationsSet.bodyworkModifications.defaultMessage))
            }
        },
        [`bodyWorkMods${index}`]: {
            availableValues: !_.isEmpty(modificationOptions.bodyworkModifications) ? modificationOptions.bodyworkModifications.map((modification) => ({
                code: modification,
                name: modification
            })) : [],
            validationMessages: (!vehicleVM.bodyWorkMods?.value?.length) && isBodyWorkModsVisible && submitted ? requireFieldError : [],
            visible: isBodyWorkModsVisible,
            placeholder: {
                id: 'translation.bodyWorkMods.placeholder',
                defaultMessage: isBodyWorkModsVisible ? messagesTranslationsSet["typeAheadDropdownDefault"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            }
        },
        [`bodyWorkModsButton${index}`]: {
            onClick: () => {
                toggleBodyWorkModsButtonAction();
            },
            content: {
                id: isBodyWorkModsVisible ? messagesTranslationsSet["removeModifiedButton"].id : messagesTranslationsSet["addModifiedButton"].id,
                defaultMessage: isBodyWorkModsVisible ? messagesTranslationsSet["removeModifiedButton"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            },
            className: !isBodyWorkModsVisible ? "addModifiedCarButton" : "addModifiedCarButtonWide"
        },
        [`engineModifications${index}`]: {
            content: {
                id: translator(messagesTranslationsSet.engineModifications.id),
                defaultMessage: htmlParser(translator(messagesTranslationsSet.engineModifications.defaultMessage))
            }
        },
        [`engineMods${index}`]: {
            availableValues: !_.isEmpty(modificationOptions.engineModifications) ? modificationOptions.engineModifications.map((modification) => ({
                code: modification,
                name: modification
            })) : [],
            validationMessages: (!vehicleVM.engineMods?.value?.length) && isEngineModsVisible && submitted ? requireFieldError : [],
            visible: isEngineModsVisible,
            placeholder: {
                id: 'translation.engineMods.placeholder',
                defaultMessage: isEngineModsVisible ? messagesTranslationsSet["typeAheadDropdownDefault"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            }
        },
        [`engineModsButton${index}`]: {
            onClick: () => {
                toggleEngineModsAction();
            },
            content: {
                id: isEngineModsVisible ? messagesTranslationsSet["removeModifiedButton"].id : messagesTranslationsSet["addModifiedButton"].id,
                defaultMessage: isEngineModsVisible ? messagesTranslationsSet["removeModifiedButton"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            },
            className: !isEngineModsVisible ? "addModifiedCarButton" : "addModifiedCarButtonWide"
        },
        [`wheelsOrTyreModifications${index}`]: {
            content: {
                id: translator(messagesTranslationsSet.wheelsOrTyreModifications.id),
                defaultMessage: htmlParser(translator(messagesTranslationsSet.wheelsOrTyreModifications.defaultMessage))
            }
        },
        [`wheelsOrTyresMods${index}`]: {
            availableValues: !_.isEmpty(modificationOptions.wheelsOrTyresModifications) ? modificationOptions.wheelsOrTyresModifications.map((modification) => ({
                code: modification,
                name: modification
            })) : [],
            validationMessages: (!vehicleVM.wheelsOrTyresMods?.value?.length) && isWheelsTiresModsVisible && submitted ? requireFieldError : [],
            visible: isWheelsTiresModsVisible,
            placeholder: {
                id: 'translation.engineMods.placeholder',
                defaultMessage: isWheelsTiresModsVisible ? messagesTranslationsSet["typeAheadDropdownDefault"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            }
        },
        [`wheelsOrTyreModsButton${index}`]: {
            onClick: () => {
                toggleWheelTireModsAction();
            },
            content: {
                id: isWheelsTiresModsVisible ? messagesTranslationsSet["removeModifiedButton"].id : messagesTranslationsSet["addModifiedButton"].id,
                defaultMessage: isWheelsTiresModsVisible ? messagesTranslationsSet["removeModifiedButton"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            },
            className: !isWheelsTiresModsVisible ? "addModifiedCarButton" : "addModifiedCarButtonWide"
        },
        [`otherModifications${index}`]: {
            content: {
                id: translator(messagesTranslationsSet.otherModifications.id),
                defaultMessage: htmlParser(translator(messagesTranslationsSet.otherModifications.defaultMessage))
            }
        },
        [`otherMods${index}`]: {
            availableValues: !_.isEmpty(modificationOptions.otherModifications) ? modificationOptions.otherModifications.map((modification) => ({
                code: modification,
                name: modification
            })) : [],
            validationMessages: (!vehicleVM.otherMods?.value?.length) && isOtherModsVisible && submitted ? requireFieldError : [],
            visible: isOtherModsVisible,
            placeholder: {
                id: 'translation.engineMods.placeholder',
                defaultMessage: isOtherModsVisible ? messagesTranslationsSet["typeAheadDropdownDefault"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            }
        },
        [`otherModsButton${index}`]: {
            onClick: () => {
                toggleOtherModsAction();
            },
            content: {
                id: isOtherModsVisible ? messagesTranslationsSet["removeModifiedButton"].id : messagesTranslationsSet["addModifiedButton"].id,
                defaultMessage: isOtherModsVisible ? messagesTranslationsSet["removeModifiedButton"].defaultMessage : messagesTranslationsSet["addModifiedButton"].defaultMessage
            },
            className: !isOtherModsVisible ? "addModifiedCarButton" : "addModifiedCarButtonWide"
        },
        [`datePurchased${index}`]: {
            visible: _.get(vehicleVM, 'firstRegisteredYear.value', '') !== '',
            onValueChange: handleDatePurchasedValueChange,
            value: purchasedCarMonthYear,
            maxDate: {
                day: new Date(basePeriodStartDate).getDay(),
                month: new Date(basePeriodStartDate).getMonth() + 1,
                year: new Date(basePeriodStartDate).getFullYear()
            },
            minDate: {
                day: new Date(minRegisteredYear).getDay(),
                month: new Date(minRegisteredYear).getMonth() + 1,
                year: new Date(minRegisteredYear).getFullYear() - 1
            },
            validationMessages: purchasedDateValidationsMessage()
        },
        [`dayTimeParking${index}`]: {
            availableValues: !_.isEmpty(vehicleVM) ? vehicleVM.dayTimeParking.aspects.availableValues[0].typelist.getFilter('NonMigratingPoliciesDayTimeParking').codes.map((typeCode) => ({
                code: typeCode.code,
                name: translator({
                    id: typeCode.name,
                    defaultMessage: typeCode.name
                })
            })) : [],
            validationMessages: vehicleVM.dayTimeParking.value === undefined && submitted ? requireFieldError : []
        },
        [`nightTimeParking${index}`]: {
            validationMessages: vehicleVM.nightTimeParking.value === undefined && submitted ? requireFieldError : []
        },
        [`marketValue${index}`]: {
            onValueChange: handleValueChange,
            validationMessages: (vehicleVM.marketValue?.amount?.value == '' || vehicleVM.marketValue?.amount?.value == null) ? requireFieldError : [],
            secondaryLabel: htmlParser(translator(messagesTranslationsSet[`subtextCostOfReplacement${brand.toUpperCase()}`].defaultMessage)),
            value: marketValue
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    const data = {
        vehicle: vehicleVM
    };
    return (
        <ViewModelForm
            model={data}
            uiProps={formattedMetadata}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={handleValueChange}
            showErrors={submitted}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

PMVehicleComponent.propTypes = {
    data: PropTypes.shape({}),
    phoneWide: PropTypes.shape({}),
    labelPosition: PropTypes.string,
    path: PropTypes.string,
    onValueChange: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    readOnlyFields: PropTypes.arrayOf(PropTypes.string),
    showOptional: PropTypes.bool,
    index: PropTypes.number,
    submitted: PropTypes.bool,
    id: PropTypes.string.isRequired
};
PMVehicleComponent.defaultProps = {
    data: {},
    labelPosition: 'left',
    phoneWide: {
        labelPosition: 'top'
    },
    path: undefined,
    readOnlyFields: [],
    showOptional: true,
    index: 0,
    submitted: false,
};
export default PMVehicleComponent;
